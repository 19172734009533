@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  overflow-y: scroll;
}

@layer components {
  /* Customize scrollbar styles */
  ::-webkit-scrollbar {
    width: 0.5vw; /* Adjust width */
    height: 0.25vh; /* Adjust height */
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff; /* Adjust track background color */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9c9c9c; /* Adjust thumb background color */
    border-radius: 0.5vw; /* Adjust thumb border-radius */
  }
}

@media (min-height: 580px) {
  .report-container {
    display: block;
  }
}

/* custom styles for antd */
.ant-picker .ant-picker-suffix {
  pointer-events: auto !important;
}
