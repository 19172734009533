.week-picker {
  border-radius: 4px !important;
  border: 1px solid #c4c4c4 !important;
  box-shadow: none !important;
  min-height: 56px !important;
  min-width: 18rem !important;
  width: 284px !important;
}

/* hover effect matching mui component */
.week-picker:hover {
  border-color: #14213d !important;
}
.ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell::before {
  background-color: #e6f4ff !important;
  color: #000000e0 !important;
}
.ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell-week::before {
  background-color: #14213d !important;
}
.ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell .ant-picker-cell-inner {
  color: #000000e0;
}
.ant-picker-week-panel-row .ant-picker-week-panel-row-hover {
  background-color: #e6f4ff !important;
}
.ant-picker-dropdown .ant-picker-week-panel-row-hover td.ant-picker-cell .ant-picker-cell-inner {
  color: #000000e0;
}
.ant-picker-dropdown .ant-picker-week-panel-row-hover td.ant-picker-cell::before {
  background-color: #e6f4ff;
}
.ant-picker-dropdown
  .ant-picker-week-panel-row-selected
  td.ant-picker-cell-week
  .ant-picker-cell-inner {
  color: #fff;
}

/* controls the input field width */
@media (max-width: 600px) {
  .week-picker {
    width: 100% !important;
  }
}
